.post {
  background-color: #a17ee0; /* Set background color */
  border-radius: 8px;
  margin-bottom: 20px; /* Increase margin between posts */
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow for depth */
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.3s ease; /* Add smooth transition effects */

  &:hover {
    background-color: #8a62bd; /* Darker background color on hover */
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 255); /* Increase box shadow on hover */
    transform: translateY(-2px); /* Lift the post slightly on hover */
  }

  .post-title {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff; /* White title text color */
    margin-bottom: 10px;
    overflow-wrap: break-word; /* Wrap long words */
  }

  .post-text {
    font-size: 16px;
    color: #ffffff; /* White post text color */
    margin-bottom: 10px; /* Increase margin between title and text */
    overflow-wrap: break-word; /* Wrap long words */
  }

  .post-username {
    font-size: 14px;
    color: #ffffff; /* White username text color */
    margin-bottom: 10px; /* Increase margin between text and username */
  }

  .like-post {
    margin-top: 10px;
    color: #ffffff; /* White like button color */
    cursor: crosshair;
    transition: color 0.3s ease; /* Add smooth transition for color change */

    &:hover {
      color: #d612bc; /* Change like button color on hover */
    }
  }

  label {
    color: #ffffff; /* White label color */
  }
}

.posts-container {
  margin-top: 75px; /* Adjust margin to account for top bar */
  max-height: calc(100vh - 75px); /* Adjust max height to fit viewport */
  overflow-y: auto; /* Add vertical scrollbar for overflow content */
  padding-left: 0; /* Remove left padding for all devices */
}

.Codys-page {
  font-size: 30px;
  color: #ffffff; /* Set vibrant white color */
  margin-bottom: 20px; /* Add some space between the title and posts */
}

.add-post {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 24px; /* Keep icon size */
  color: #4d4d4e; /* Set icon color */
  background-color: transparent; /* Set transparent background */
  border: 2px solid #4d4d4e; /* Add border */
  padding: 5px; /* Add some padding */
  transition: transform 0.3s ease, border-color 0.3s ease; /* Add transition */
}

.add-post:hover {
  transform: scale(1.1); /* Scale up on hover */
  border-color: hsl(266, 41%, 56%); /* Change border color on hover */
}

.post-label {
  font-size: 40px;
  color: #8a62bd;
}

.sort-by {
  background-color: #a17ee0;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-left: 200px;
  width: 300px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .posts-container {
    margin-left: 70px; /* Add margin for desktop devices */
  }
}

.Codys-page {
  font-size: 30px;
}

@media screen and (max-width: 768px) {
  .posts-container {
    max-height: calc(100vh - 75px); /* Adjust max height to fit viewport */
    overflow-y: auto; /* Add vertical scrollbar for overflow content */
    padding-left: 0; /* Remove left padding */
  }
  .post-title {
    font-size: 18px; /* Adjust title font size for smaller screens */
  }

  .post-text {
    font-size: 14px; /* Adjust post text font size for smaller screens */
  }

  .post-username {
    font-size: 12px; /* Adjust username font size for smaller screens */
  }

  .like-post {
    margin-left: auto; /* Move like button to the right for smaller screens */
    margin-top: 10px; /* Add some space between like button and post content */
  }

  .icon {
    margin-top: 70px;
    font-size: 20px; /* Adjust icon size for smaller screens */
  }

  .sort-by {
    background-color: #a17ee0;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-left: 200px;
    width: 175px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
}
