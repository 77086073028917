.page-container {
  display: flex;
}

.sidebar {
  background-color: #002909; /* Match the sidebar background color */
  width: 60px; /* Match the width of your sidebar */
  height: 100vh; /* Take full height of the viewport */
}

.settings-page {
  flex: 1; /* Take up remaining space */
  padding: 20px;
  color: #4d4d4e;
  background-color: #000000; /* Adjust background color as needed */
  box-sizing: border-box;
}

.settings-page h1 {
    text-align: center;
    margin-bottom: 20px;
}

.settings-content {
  margin-top: 20px;
}

.setting-option {
  margin-bottom: 10px;

  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }

  input[type='text'],
  select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
}

.logout-link-button {
    content: 'Log in';
    font-family: 'La Belle Aurore';
    font-size: 18px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    cursor: pointer;
    color: #8a2be2;
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
    background-color: #000000;
}

@media screen and (max-width: 1200px) {
  .logout-link-button {
    content: 'Log in';
    font-family: 'La Belle Aurore';
    font-size: 18px;
    position: absolute;
    margin-top: 265px;
    right: 55px;
    cursor: pointer;
    color: #8a2be2;
    opacity: 0;
}
.sidebar {
  display: none;
}
}