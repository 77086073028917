.nav-bar {
  background: #002909;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;

  @media screen and (min-width: 1201px){
  .linkedin-link,
  .github-link,
  .gitlab-link,
  .linkedin-link { // Adjust this selector according to your needs
    display: none;
  }}

  .logo {
    display: block;
    padding: 8px 0;

    img {
      display: block;
      margin: 8px auto;
      width: 24px;
      height: auto;

      &.sub-logo {
        width: 50px;
      }
    }
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -170px;
    width: 100%;

    a {
      font-size: 22px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #8a2be2;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &::after {
          content: 'HOME';
        }
      }
    }

    a.games-link {
      &:after {
        content: 'GAMES';
      }
    }

    a.weather-link {
      &:after {
        content: 'WEATHER';
      }
    }

    a.social-link {
      &:after {
        content: 'SOCIAL';
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.active {
      svg {
        color: #8a2be2;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 15px;
        line-height: 16px;

        &:hover svg {
          color: #8a2be2;
        }
      }
    }
  }
}

.settings-link {
  &:hover {
    color: #8a2be2;
  }
}

.hamburger-icon,
.close-icon {
  display: none;
}

@media screen and (max-width: 1200px) {
  .nav-bar {
    background: transparent;
    position: initial;
    height: auto;
    min-height: auto;
    width: 200px;

    ul,
    nav {
      display: none;
      overflow-x: auto;
      white-space: nowrap;
    }

    nav {
      width: 100%;
      height: 6%;
      background: #002909;
      top: 0;
      left: 0;
      position: fixed;
      z-index: 2;
      margin: 0;

      .close-icon {
        display: flex;
        margin-top: 15px;
        margin-right: 10px;
        font-size: 20px; // Adjust the font size to match other icons
        cursor: pointer; // Add cursor pointer for interaction
      }


      a {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0 10px;
        font-size: 15px;
      }

      a:after {
        opacity: 1;
        position: initial;
        width: auto;
        margin-left: 10px;
      }

      a svg {
        opacity: 1 !important;
      }

      &.mobile-show {
        display: flex;
        justify-content: flex-start;
      }

      a.games-link {
        display: none;
      }
    }

    .hamburger-icon {
      display: block;
      position: absolute;
      top: 19px;
      right: 10px;
    }

  }
  .logo {
    display: none;

    img {
      display: none;

      &.c-logo {
        visibility: hidden;
      }

      &.sub-logo {
        display: none;
      }
    }
  }
}
