.title {
  color: #a17ee0;
  font-size: 56px;
  font-weight: 700;
}

.daily-item {
  background-color: #b038b4;
  border-radius: 6px;
  height: 16px;
  margin: 2px;
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 17px;
  padding: 2px 50px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.3s ease;
}

.daily-item:hover {
  background-color: #a17ee0;
  box-shadow: 0 0 10px 5px rgba(0, 255, 255, 0.5);
  transform: scaleX(1.05) scaleY(1.1);
}

.icon-small {
  width: 15px;
}

.daily-item .day {
  cursor: inherit;
  color: #1e469e;
  flex: 1 1;
  font-weight: 600;
  margin-left: 5px;
  margin-top: 0px;
}

.description {
  cursor: inherit;
  flex: 1 1;
  margin-right: 5px;
  text-align: right;
  margin-top: 0px;
}

.min-max {
  color: #ffffff;
  margin-top: 0px;
}

.daily-details-grid {
  grid-row-gap: 0;
  grid-column-gap: 6px;
  -webkit-column-gap: 6px;
  column-gap: 6px;
  display: grid;
  flex: 1 1;
  grid-template-columns: auto auto;
  padding: 2px 6px;
  row-gap: 0;
}

.daily-details-grid-item {
  align-items: center;
  display: flex;
  height: 11px;
  justify-content: space-between;
}

.daily-details-grid-item label:first-child {
  color: #ffffff;
}

.daily-details-grid-item label:last-child {
  color: #ffffff;
}

@media screen and (max-width: 1200px) {
  .weather {
    width: 90%; /* Adjust width for smaller screens */
    padding: 15px; /* Reduce padding for smaller screens */
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .top,
  .bottom {
    flex-direction: column; /* Stack items vertically on smaller screens */
    text-align: center; /* Center-align text on smaller screens */
  }

  .weather-icon {
    width: 80px; /* Adjust weather icon size for smaller screens */
    margin-bottom: 10px; /* Add some space below the weather icon */
  }

  .temperature {
    font-size: 50px; /* Adjust temperature font size for smaller screens */
    letter-spacing: -3px; /* Adjust letter spacing for smaller screens */
  }

  .details {
    padding-left: 0; /* Remove left padding on details for smaller screens */
  }

  .parameter-row {
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: space-between;
  }

  .parameter-label {
    flex: 1; /* Take up available space */
    text-align: left;
    font-weight: 400;
    font-size: 12px;
  }

  .parameter-value {
    flex: 1; /* Take up available space */
    text-align: right;
    font-weight: 600;
    font-size: 12px;
  }

  .parameter-label.top,
  .parameter-label.bottom {
    border-bottom: none; /* Remove border bottom on parameter labels for smaller screens */
  }

  /* Forecast section styles */

  .title {
    font-size: 36px; /* Adjust title font size for smaller screens */
    margin-top: 40px; /* Add space between weather and forecast sections */
  }

  .daily-item {
    font-size: 16px; /* Adjust font size for daily items */
    padding: 10px 20px; /* Adjust padding for daily items */
  }

  .icon-small {
    width: 20px; /* Adjust small icon size */
  }

  .daily-item .day {
    margin-left: 10px; /* Adjust margin for day */
  }

  .daily-details-grid {
    grid-column-gap: 10px; /* Adjust grid column gap */
    padding: 10px; /* Adjust padding for daily details grid */
  }

  .daily-details-grid-item {
    height: auto; /* Allow height to adjust based on content */
  }
}
