.page-not-found-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
}

.page-not-found-content {
    text-align: center;
}

.page-not-found-title {
    font-size: 30px;
    color: white;
}

.page-not-found-message {
    margin-top: 10px;
    font-size: 20px;
    color: white;
}

