.profile-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  position: relative;

  .profile-page-container-words {
    display: flex;
    margin-right: 90px;
    flex-direction: column;
    align-items: center;
    font-size: 60px;
    color: #a17ee0;
    margin-bottom: 40px;
  }

  .profile-page-basic-info {
    margin-right: 90px;
    font-size: 20px;
    color: #a17ee0;
  }

  .profile-page-list-of-user-posts {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    margin-left: 70px;
    margin-top: 230px;
    max-width: calc(200px * 4 + 5px * 3);
    height: 400px;
    overflow-y: auto;

    .profile-page-post {
      width: 175px;
      height: 175px;
      background-color: #a17ee0;
      margin-top: 15px;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 10px;
      border-radius: 20px;
      padding: 20px;
      overflow: hidden;
      cursor: pointer;
      transition: transform 0.2s ease;

      &:hover {
        transform: scale(1.05);
        background-color: #8a62bd; /* Darker background color on hover */
        box-shadow: 0 0 10px 5px rgba(255, 255, 255, 255); /* Increase box shadow on hover */
      }

      .profile-page-post-title {
        font-weight: bold;
        margin-bottom: 5px;
        cursor: pointer;
        color: #ffffff;
      }

      .profile-page-post-text {
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #ffffff;
      }

      .dots {
        margin-top: 120px;
        margin-left: 180px;
      }
    }
  }
  .sort-by-profile {
    background-color: #a17ee0;
    border-radius: 8px;
    margin-top: 40px;
    margin-left: 400px;
    width: 300px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
}

.create-post-link {
  position: absolute;
  top: 185px;
  left: 340px;
  font-size: 24px; /* Keep icon size */
  color: #4d4d4e; /* Set icon color */
  background-color: transparent; /* Set transparent background */
  border: 1px solid #4d4d4e; /* Add border */
  padding: 5px; /* Add some padding */
  transition: transform 0.3s ease, border-color 0.3s ease; /* Add transition */
}

.create-post-link:hover {
  transform: scale(1.1); /* Scale up on hover */
  border-color: #ffffff; /* Change border color on hover */
}

.profile-post-label {
  position: absolute;
  top: 180px;
  left: 380px;
  font-size: 30px;
  color: #8a62bd;
}

@media screen and (max-width: 768px) {
  .profile-page-container {
    .sort-by-profile {
      margin-right: 400px;
      width: 150px;
    }
    .create-post-link{
      display: none;
    }
    .profile-post-label {
      display: none;
    }
  }
}
