.games-container {
  display: flex;
  justify-content: center;
  align-items: center; /* Center the content vertically and horizontally */
  height: 100vh; /* Make the container take the full height of the viewport */
  width: 100%;
  box-sizing: border-box;
  flex-direction: column; /* Ensure the description is above the button */
}

.game {
  opacity: 0;
  animation: pop-up 5s ease forwards; /* Adjust the animation duration */
  display: flex;
  flex-direction: column; /* Stack description and button vertically */
  align-items: center;
  text-align: center; /* Center align the text */
}

@keyframes pop-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.game-description {
  margin-bottom: 40px; /* Space between description and button */
  font-size: 18px;
  max-width: 600px;
  color: #ffd700;
}

.game-button {
  position: relative;
  border: none;
  background-image: url('../../assets/images/Boomin.png');
  padding: 100px 20px; /* Adjust padding to make the button larger */
  border-radius: 21px;
  width: 400px;
  height: 200px;
  cursor: crosshair;
  font-size: 24px;
  transition: box-shadow 0.3s ease, background-color 0.3s ease, transform 0.3s ease;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-button:hover {
  background-color: #ffeb3b;
  box-shadow: 0 0 20px rgba(255, 255, 0, 0.5);
  transform: translateY(-10px) scale(1.1);
}

.game-button:hover .play-game {
  opacity: 0; /* Hide the text on hover */
  visibility: hidden; /* Hide the text on hover */
}

.play-game {
  margin-bottom: 70px;
  color: #fff;
}