.weather {
  width: 300px;
  border-radius: 6px;
  box-shadow: 0 0 10px 5px rgba(0, 255, 255, 0.5);
  color: #fff;
  background-color: #2763a7;
  margin: 20px auto 0 auto;
  padding: 20px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.weather:hover {
  background-color: #51b1f1;
  box-shadow: 0 0 15px 8px rgba(0, 255, 255, 0.7);
}

.top,
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.city {
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  margin: 0;
  letter-spacing: 1px;
}

.weather-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  margin: 0;
}

.weather-icon {
  width: 100px;
}

.temperature {
  font-weight: 600;
  font-size: 70px;
  width: auto;
  letter-spacing: -5px;
  margin: 10px 0;
}

.details {
  width: 100%;
  padding-left: 20px;
}

.parameter-row {
  display: flex;
  justify-content: space-between;
}

.parameter-label {
  text-align: left;
  font-weight: 400;
  font-size: 12px;
}

.parameter-value {
  text-align: right;
  font-weight: 600;
  font-size: 12px;
}

.parameter-label.top,
.parameter-label.bottom {
  border-bottom: 1px solid #fff;
}

@media screen and (max-width: 1200px) {
  .weather {
    width: 90%; /* Adjust width for smaller screens */
    padding: 15px; /* Reduce padding for smaller screens */
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .top,
  .bottom {
    flex-direction: column; /* Stack items vertically on smaller screens */
    text-align: center; /* Center-align text on smaller screens */
  }

  .weather-icon {
    width: 80px; /* Adjust weather icon size for smaller screens */
    margin-bottom: 10px; /* Add some space below the weather icon */
  }

  .temperature {
    font-size: 50px; /* Adjust temperature font size for smaller screens */
    letter-spacing: -3px; /* Adjust letter spacing for smaller screens */
  }

  .details {
    padding-left: 0; /* Remove left padding on details for smaller screens */
  }

  .parameter-row {
    flex-direction: column; /* Stack parameter rows vertically on smaller screens */
  }

  .parameter-label,
  .parameter-value {
    text-align: center; /* Center-align parameter labels and values on smaller screens */
  }

  .parameter-label.top,
  .parameter-label.bottom {
    border-bottom: none; /* Remove border bottom on parameter labels for smaller screens */
  }
}