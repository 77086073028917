.login-container {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000000;

  .form-wrapper {
    padding: 20px;
    border-radius: 8px;
    width: 300px;

    form {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 8px;
        color: #006400; // Adjust label color to match login page
      }

      .input-field {
        padding: 8px;
        margin-bottom: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        color: #006400; // Adjust input text color to match login page
      }

      .login-button {
        padding: 10px 20px;
        background-color: #006400; // Adjust button color to match login page
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;

        &:hover {
          background-color: darken(#006400, 10%);
        }
      }
    }
  }
}
