$darkBlack: #000000;
$darkGreen: #006400;

.back-button {
  position: absolute;
  background-color: #a17ee0;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  top: 40px;
  left: 80px;

  &:hover {
    background-color: darken(#a17ee0, 10%);
  }
}

.left-side-post {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #a17ee0; /* Updated background color */
  border-radius: 8px;
  margin-top: 100px;
  margin-left: 80px;
  height: 400px;
  position: relative;
}

.post-options {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
}

.comment-options {
  margin-left: 15px;
  margin-bottom: 5px;
  width: auto;
  height: auto;
  cursor: pointer;
}

.post-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ffffff; /* Updated post title text color */
  overflow-wrap: break-word; /* Wrap long words */
  overflow-y: auto;
}

.post-text {
  font-size: 18px;
  margin-bottom: 10px;
  color: #ffffff; /* Updated post text color */
  overflow-wrap: break-word; /* Wrap long words */
  overflow-y: auto;
}

.right-side-post {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #a17ee0;
  border-radius: 8px;
  margin-top: -520px;
  margin-right: 20px;
  height: 530px;
  position: relative;
}

.comment {
  color: rgb(0, 0, 0);
}

.comment-username {
  color: rgb(255, 255, 255);
}

.comments-section {
  text-align: left;
}

.comments-section h2 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
  color: #ffffff; /* Updated comments section title text color */
}

.list-of-comments {
  overflow-y: auto;
  max-height: 420px;
  overflow-wrap: break-word; /* Wrap long words */
}

.add-comment {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  width: calc(100% - 40px);
}

.add-comment input,
.add-comment button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
}

.add-comment input {
  flex: 1;
  margin-right: 10px;
}

.add-comment button {
  background-color: $darkGreen;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;

  &:hover {
    background-color: darken($darkGreen, 10%);
  }
}

.delete-comment {
  margin-left: 10px;
  height: 20px;
  font-size: 10px;
  cursor: pointer;
}

.delete-comment:hover {
  background-color: red;
}

.edit-comment {
  margin-left: 10px;
  height: 20px;
  font-size: 10px;
  cursor: pointer;
}
.edit-comment:hover {
  background-color: rgb(17, 250, 17);
}

@media screen and (max-width: 768px) {
  .left-side-post {
    max-width: calc(100% - 40px); /* Adjusted max-width for smaller screens */
    margin: 20px auto; /* Center align on smaller screens */
    overflow-y: auto;

    .delete-post-button {
      margin-top: 20px;
      margin-left: auto;
      margin-right: 20px;
    }
  }

  .right-side-post {
    max-width: calc(100% - 40px); /* Adjusted max-width for smaller screens */
    margin-top: 20px; /* Adjusted margin for smaller screens */
    margin-right: auto; /* Center align on smaller screens */
    margin-left: auto; /* Center align on smaller screens */
  }

  .back-button {
    position: relative;
    margin-bottom: 50px;
    left: 140px;

    &:hover {
      background-color: darken(#a17ee0, 10%);
    }
  }
}
