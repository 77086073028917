$darkBlack: #000000;
$darkGreen: #006400;

.change-username-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $darkBlack;
  color: $darkGreen;
}

.password-field{
  width: 300px;
  height: 40px;
  margin-bottom: 10px;
  padding: 5px;
  border: none;
  border-radius: 5px;
  color: $darkGreen;
}

.change-username {
  width: 300px;
  height: 40px;
  margin-bottom: 10px;
  padding: 5px;
  border: none;
  border-radius: 5px;
  color: $darkGreen;
}

.error {
  color: red; // Change the color to your preferred color
  font-size: 14px; // Optional: Adjust the font size if needed
  margin-top: 4px; // Optional: Add some spacing between the input field and the error message
}

.change-pass:hover {
  background-color: darken($darkGreen, 10%);
}
