.logo-container {
  z-index: 0;
  width: 400px;
  height: 609px;
  opacity: 1; /* Adjust opacity as needed */
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;

  img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 75px;
    right: auto;
    bottom: auto;
    left: 10px;
    margin: auto;
    opacity: 1; /* Adjust opacity as needed */
    animation: rotateIn 1s linear both;
    animation-delay: 1.4s;
    z-index: 1;
  }
}

.svg-container {
  stroke: #ffd700;
  stroke-width: 10;
}
