$darkBlack: #000000;
$darkGreen: #006400;

.create-post-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #a17ee0; /* Set background color */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 150px;

  form {
    display: flex;
    flex-direction: column;

    label {
      margin-top: 10px;
      font-weight: bold;
      color: $darkGreen;
    }

    .input-field {
      padding: 10px;
      margin-bottom: 15px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
      color: $darkGreen;
    }

    .post-button {
      padding: 10px 20px;
      background-color: #ffffff; /* Set button background color */
      color: #a17ee0; /* Set button text color */
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
      margin-top: 10px;

      &:hover {
        background-color: #4a2888;
      }
    }

    .error-message {
      color: red;
      margin-top: 5px;
    }
  }
}
