$darkBlack: #000000;
$darkGreen: #006400;

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: $darkGreen;
}

.input-field {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 300px;
  font-size: 16px;
  color: $darkGreen;
}

.login-button {
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  background-color: $darkGreen;
}

.login-button:hover {
  background-color: darken($darkGreen, 10%);
}

.registration-link {
  color: #ccc;
  font-size: 15px;
  margin-top: 10px;
}