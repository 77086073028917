$darkBlack: #000000;
$darkGreen: #006400;

.change-password-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $darkBlack;
  color: $darkGreen;
}

.change-password {
  font-size: 24px;
  margin-bottom: 20px;
}

.change-password-old,
.change-password-new {
  position: relative; // Add relative positioning to input fields
  width: 300px;
  height: 40px;
  margin-bottom: 10px;
  padding: 5px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  color: $darkGreen;
}


.change-pass {
  width: 200px;
  height: 40px;
  margin-top: 10px; // Adjust margin-top as needed
  background-color: $darkGreen;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.change-pass:hover {
  background-color: darken($darkGreen, 10%);
}
