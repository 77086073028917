html{
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Hellvetica Neue', 'sans-serif';
  color: #444;
  background-color: #000000;
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }  
}
